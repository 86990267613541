import React from 'react';
import css from './CategoriesNav.module.css';
import {
  categoriesForMegaMenu,
  listingFields,
  products,
  sports,
} from '../../../../config/configListing';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { getSliceSecondParam } from './utils';

const CategoriesNav = props => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const categoriesOptions = categoriesForMegaMenu;
  // listingFields?.find(i => i?.key === 'category')?.enumOptions || [];

  const filteredSubcategories = hoveredCategory === 'sports' ? sports : products;
  // subcategoriesOptions.filter(s => s?.category === hoveredCategory);
  const history = useHistory();
  if (categoriesOptions?.length === 0) {
    return null;
  }
  const secondSliceParam = getSliceSecondParam();
  return (
    <div className={css.wrapper}>
      {categoriesOptions.slice(0, secondSliceParam).map(c => {
        const isCategoryHovered = hoveredCategory === c?.option;
        return (
          <div
            key={c?.option}
            onMouseLeave={() => setHoveredCategory(null)}
            onMouseEnter={() => setHoveredCategory(c?.option)}
            // className={css.item}
            className={`${css.item} ${isCategoryHovered ? css.itemHovered : ''}`}
            onClick={() => {
              if (c.option != 'sports') history.push(`/s?pub_category=${c?.option}`);
            }}
          >
            {c?.label}
          </div>
        );
      })}
      {hoveredCategory && (
        <div
          className={css.subcategoriesWrapper}
          onMouseLeave={() => {
            setHoveredCategory(null);
          }}
          onMouseEnter={() => setHoveredCategory(hoveredCategory)}
        >
          {filteredSubcategories.map(s => {
            return (
              <div
                className={css.subcategoryItem}
                onClick={() => {
                  console.log('hoveredCategory', hoveredCategory);
                  if (hoveredCategory === 'sports') {
                    history.push(`/s?pub_sport=${s?.option}`);
                  } else {
                    history.push(`/s?pub_category=${hoveredCategory}&pub_product=${s?.option}`);
                  }
                  // history.push(`/s?pub_subcategory=${s?.option}`)
                }}
              >
                {s?.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CategoriesNav;
