import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { NamedLink, Logo } from '../../components';

import css from './LinkedLogo.module.css';

const LinkedLogo = props => {
  const { rootClassName, format, alt, ...rest } = props;
  let className = props.className;
  let logoClassName = props.logoClassName;
  if (format === 'mobile') {
    logoClassName = classNames(css.logoMobile, props.className);
  } else {
    logoClassName = classNames(css.logo, props.className);
  }
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={css.logoContainer}>
      <NamedLink className={classes} name="LandingPage" {...rest}>
        <Logo format={format} className={logoClassName || css.logo} alt={alt} />
      </NamedLink>
    </div>
  );
};

LinkedLogo.defaultProps = {
  className: null,
  rootClassName: null,
  logoClassName: null,
  format: 'desktop',
};

LinkedLogo.propTypes = {
  className: string,
  rootClassName: string,
  logoClassName: string,
  format: oneOf(['desktop', 'mobile']),
};

export default LinkedLogo;
