export const getSliceSecondParam = () => {
  const screenWidth = typeof window !== 'undefined' && window.innerWidth;
  let sliceParam = 6;
  if (screenWidth < 1250) {
    sliceParam = 5;
  }
  if (screenWidth < 1025) {
    sliceParam = 4;
  }

  return sliceParam;
};
